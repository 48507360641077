import { Link } from "react-router-dom"

export default function NavItem(props) {
    let param="";
    if(props.id==="1"){
        param = "?name=tastyicecream";
    }else if(props.id==="2"){
        param = "?name=tastylounge";
    }
    if(props.link==="/#gallery"){
        return (
            <>
              <li  key={props.children} className="md:mr-2 w-fit  hover:scale-105 duration-700">
             <a href={props.link}  onClick={() => props.setIsOpen(false)} className={(props.state==="active")? "font-bold  w-fit text-xl p-2 text-primary font-josef UnderLineHover duration-500 rounded-lg" : "font-normal w-fit text-xl p-2 text-black opacity-70 font-josef UnderLineHover duration-500 rounded-lg"}> {props.children} </a>
             </li>
            </>
         )
    }else{
        return (
            <>
              <li  key={props.children} className="md:mr-2 w-fit hover:scale-105 duration-700">
             <Link   onClick={() => props.setIsOpen(false)} to={props.link+param} className={(props.state==="active")? "font-bold  w-fit text-xl p-2 text-primary font-josef UnderLineHover duration-500 rounded-lg" : "font-normal w-fit text-xl p-2 text-black opacity-70 font-josef UnderLineHover duration-500 rounded-lg"}> {props.children} </Link>
             </li>
            </>
         )
    }

  }
