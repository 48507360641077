export default function MainTitle(props){

    if(props.color !== undefined){
        return (
            <div className="lg:mt-56 mt-36 flex gap-8 items-center justify-center mx-auto w-full">
               <svg width="w-fill-av"  height="13" viewBox="0 0 456 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M455.774 6.5L450 0.726498L444.227 6.5L450 12.2735L455.774 6.5ZM0 7.5L450 7.5V5.5L0 5.5L0 7.5Z" fill="url(#paint0_linear_720_662)"/>
            <defs>
            <linearGradient id="paint0_linear_720_662" x1="6.43045" y1="6.49998" x2="455.145" y2="6.49824" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0"/>
            <stop offset="1" stopColor="white"/>
            </linearGradient>
            </defs>
            </svg>


                            <h1 className="font-sand text-white font-bold 2xl:text-5xl xl:text-3xl lg:text-2xl text-2xl uppercase w-fill-av w-full mx-auto text-center">{props.title}</h1>
                            <svg width="w-fill-av"  height="13" viewBox="0 0 456 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.226498 6.5L6 12.2735L11.7735 6.5L6 0.726498L0.226498 6.5ZM6 7.5H456V5.5H6V7.5Z" fill="url(#paint0_linear_720_661)"/>
            <defs>
            <linearGradient id="paint0_linear_720_661" x1="12.4304" y1="6.49998" x2="461.145" y2="6.49824" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="0.0001" stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            </defs>
            </svg>

            </div>
        )
    }else{
        return (
            <div className="lg:mt-64 mt-36 flex gap-8 items-center justify-center w-full">
                <svg width="w-fill-av" height="12" viewBox="0 0 536 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M535.774 6L530 0.226498L524.227 6L530 11.7735L535.774 6ZM0 7L530 7V5L0 5L0 7Z" fill="url(#paint0_linear_613_210)"/>
                <defs>
                <linearGradient id="paint0_linear_613_210" x1="7.57364" y1="5.99998" x2="536.059" y2="5.99756" gradientUnits="userSpaceOnUse">
                <stop stopOpacity="0"/>
                <stop offset="1"/>
                </linearGradient>
                </defs>
                </svg>

                <h1 className="font-sand font-bold 2xl:text-5xl xl:text-3xl lg:text-2xl text-2xl uppercase w-fill-av mx-auto text-center">{props.title}</h1>
                        <svg width="w-fill-av " height="12" viewBox="0 0 535 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.226498 6L6 11.7735L11.7735 6L6 0.226498L0.226498 6ZM6 7H535V5H6V7Z" fill="url(#paint0_linear_613_209)"/>
            <defs>
            <linearGradient id="paint0_linear_613_209" x1="13.5593" y1="5.99998" x2="541.048" y2="5.99757" gradientUnits="userSpaceOnUse">
            <stop/>
            <stop offset="1" stopOpacity="0"/>
            </linearGradient>
            </defs>
            </svg>

            </div>
        )
    }

}
