
import 'lazysizes';
export default function Section1() {
    return (
        <div className="2xl:max-w-[1500px] xl:max-w-[1200px] max-w-[90%] mx-auto flex justify-between py-24 pt-32 flex-col lg:flex-row gap-5 aboutus" >
            <div className="flex justify-start flex-col items-start 2xl:max-w-[600px] xl:max-w-[400px] lg:max-w-[35%]" >
                <h1 className=" uppercase font-sand font-light text-2xl">ABOUT US</h1>
                <h1 className="uppercase font-sand font-bold text-5xl text-start">dISCOVER MORE ABOUT <span className="text-primary">TASTY</span> FOOD</h1>
                <p className="font-ops text-lg text-black opacity-70">tasty is a cozy place where you can enjoy delicious food and drinks while gathering with your family, friends or colleagues. We are known for our variety of beverages and yummies</p>
            </div>
            <div className="relative 2xl:max-w-[800px] xl:max-w-[600px] h-full lg:max-w-[45%]">
                <img data-src="./assets/pic1.png?v=123456" className="w-full h-full lazyload" alt="Tasty"  id="aboutus" width={400} height={500} />
                <img data-src="./assets/pic2.png?v=123456" className="absolute  -bottom-16 lg:-left-16 left-6  w-40 lg:w-fit aboutus lazyload" alt="Tasty"  width={400} height={500} />
            </div>
        </div>
    )
}
