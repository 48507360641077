import Hero from "./components/hero";
import Section1 from "./components/section1";
import Section2 from "./components/section2";
import Section3 from "./components/section3";
import Section4 from "./components/section4";
import Section5 from "./components/section5";
import Section6 from "./components/section6";
import MainTitle from "./components/mainTitle"
import { useEffect } from "react";



function Home() {
    const dataTRIC = {
        'title':'TASTY ROLLED ICE CREAM',
        'desc':'tasty is a cozy place where you can enjoy delicious food and drinks while gathering with your family, friends or colleagues. We are known for our variety of beverages and yummies ',
        'linkBtn1':'/catalogue?name=tastyicecream',
        'linkBtn2':'#findUsTRIC',
        'linkPic1':'pic3.png',
        'linkPic2':'pic5.png',
        'linkPic3':'pic8.png',
    }
    const dataTL = {
        'title':'Tasty Lounge',
        'desc':'tasty is a cozy place where you can enjoy delicious food and drinks while gathering with your family, friends or colleagues. We are known for our variety of beverages and yummies ',
        'linkBtn1':'/catalogue?name=tastylounge',
        'linkBtn2':'#findUsTL',
        'linkPic1':'pic6.png',
        'linkPic2':'pic7.png',
        'linkPic3':'pic10.png',
    }
    useEffect(() => {
        document.title = "Tasty";
      }, []);
  return (
    <div>
        <Hero/>
        <Section1/>
        <MainTitle title={"Discover OUR Brands"}/>
        <Section2 data={dataTRIC}/>
        <Section2 data={dataTL}/>
        <MainTitle title={"wHY CHOOSE US ?"}/>
        <Section3/>
        <Section4/>
        <div id="gallery"></div>
        <MainTitle title={"Our Gallery"}/>
        <Section5/>
        <MainTitle title={"Find Us"}/>
        <Section6/>

    </div>
  );
}

export default Home;
