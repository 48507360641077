import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import { Link } from "react-router-dom"

export default function Section2(props) {
    return (
        <div className="2xl:max-w-[1500px] xl:max-w-[1200px] max-w-[90%] mx-auto flex justify-center py-24 flex-col lg:flex-row lg:gap-5">
            <div className="flex justify-start flex-col items-end 2xl:max-w-[750px] xl:max-w-[50%] lg:max-w-[50%] gap-4" >
                <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>

                    <div className="bg-prim2 relative border-t-8 border-primary w-full flex justify-center items-start gap-8 flex-col 2xl:py-44 2xl:px-14  lg:py-24 lg:px-10 py-12 px-4">
                        <h1 className="font-josef font-bold text-4xl uppercase">{props.data.title}</h1>
                        <p className="font-ops text-lg opacity-70">{props.data.desc}</p>
                        <div className="flex gap-3">
                            <Link to={props.data.linkBtn1} className="bg-prim1 border-primary border-1 uppercase font-sand font-black px-6 py-1 text-white text-lg hover:-translate-y-1 duration-500 hover:scale-105 ">Catalogue</Link>
                            <Link to={props.data.linkBtn2} className="border-primary border-2 uppercase font-sand font-bold text-primary flex items-center gap-2 text-lg px-6 hover:-translate-y-1 duration-500">Find us</Link>
                        </div>

                        <div className="absolute top-10 lg:right-12 right-14">
                        <AnimationOnScroll animateIn="animate__swing" animateOnce={true} delay={550}>

                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_613_57)">
                                    <path d="M39.9998 0L40.5834 36.6912L53.6806 2.412L41.6798 37.09L65.7114 9.358L42.5738 37.8404L74.641 20L43.157 38.8508L79.3922 33.054L43.3598 40L79.3922 46.946L43.157 41.1492L74.641 60L42.5738 42.1596L65.7114 70.6416L41.6798 42.91L53.6806 77.5876L40.5834 43.3088L39.9998 80L39.4162 43.3088L26.319 77.5876L38.3198 42.91L14.2882 70.6416L37.4258 42.1596L5.35902 60L36.8426 41.1492L0.607422 46.946L36.6398 40L0.607822 33.054L36.8426 38.8508L5.35862 20L37.4258 37.84L14.2882 9.3584L38.3198 37.0904L26.319 2.412L39.4162 36.6908L39.9998 0Z" fill="#ffff" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_613_57">
                                        <rect width="80" height="80" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            </AnimationOnScroll>

                        </div>
                        <div className="absolute bottom-6 lg:right-[35rem] hidden lg:block">
                        <AnimationOnScroll animateIn="animate__swing" animateOnce={true} delay={250}>

                            <svg width="60" height="69" viewBox="0 0 60 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30 0L31.782 32.0497L60 34.0737L31.782 36.0977L30 68.1475L28.218 36.0977L0 34.0737L28.218 32.0497L30 0Z" fill="url(#paint0_linear_757_994)" />
                                <defs>
                                    <linearGradient id="paint0_linear_757_994" x1="6.15" y1="5.4518" x2="35.5745" y2="65.4118" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="white" />
                                        <stop offset="1" stopColor="#C0E8FF" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            </AnimationOnScroll>

                        </div>
                    </div>
                    </AnimationOnScroll>
                    <div className="max-w-[50%]">
                    <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                            <img data-src={"./assets/"+props.data.linkPic1+"?v=123456"} className='lazyload' alt="Tasty" width={400} height={500} />
                        </AnimationOnScroll>

                    </div>
            </div>
            <div className="flex justify-start flex-col items-start 2xl:max-w-[750px] xl:max-w-[50%] lg:max-w-[50%] gap-4 lg:mt-52 -mt-20" >
                <div className="max-w-[50%]">
                <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>

                    <img  data-src={"./assets/"+props.data.linkPic2+"?v=123456"} className='lazyload' alt="Tasty"  width={400} height={500} />
                    </AnimationOnScroll>

                </div>
                <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                <div className=" w-full lg:h-[700px] h-[350px] flex justify-center items-start gap-8 flex-col ">

                    <img  data-src={"./assets/"+props.data.linkPic3+"?v=123456"} className="w-full h-full object-cover lazyload" alt="Tasty" width={400} height={500} />
                </div>
                </AnimationOnScroll>


            </div>
        </div>
    )
}
