import 'lazysizes';


export default function Section5() {
    return (
        <div className="mt-12">

            <div className="lg:container mx-auto p-4 " >
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    <div className="grid gap-4">
                        <div>
                            <img
                                className="h-auto max-w-full rounded-lg hover:-rotate-3 hover:scale-110 duration-700  border-4 border-white hover:border-primary hover:border-4 lazyload"
                                data-src="./assets/pic1.png?v=123456"
                                alt="pic" width={400} height={500}
                            />
                        </div>
                        <div>
                            <img
                                className="h-auto max-w-full rounded-lg hover:-rotate-3 hover:scale-110 duration-700  border-4 border-white hover:border-primary hover:border-4 lazyload"
                                data-src="./assets/pic6.png?v=123456"
                                alt="pic" width={400} height={500}
                            />
                        </div>
                        <div>
                            <img
                                className="h-auto max-w-full rounded-lg hover:rotate-3 hover:scale-110 duration-700  border-4 border-white hover:border-primary hover:border-4 lazyload"
                                data-src="./assets/pic3.png?v=123456"
                                alt="pic" width={400} height={500}
                            />
                        </div>
                    </div>
                    <div className="grid gap-4">
                        <div>
                            <img
                                className="h-auto max-w-full rounded-lg hover:-rotate-3 hover:scale-110 duration-700  border-4 border-white hover:border-primary hover:border-4 lazyload"
                                data-src="./assets/pic4.png?v=123456"
                                alt="pic" width={400} height={500}
                            />
                        </div>
                        <div>
                            <img
                                className="h-auto max-w-full rounded-lg hover:-rotate-3 hover:scale-110 duration-700  border-4 border-white hover:border-primary hover:border-4 lazyload"
                                data-src="./assets/pic5.png?v=123456"
                                alt="pic" width={400} height={500}
                            />
                        </div>
                        <div>
                            <img
                                className="h-auto max-w-full rounded-lg hover:-rotate-3 hover:scale-110 duration-700  border-4 border-white hover:border-primary hover:border-4 lazyload"
                                data-src="./assets/pic10.png?v=123456"
                                alt="pic" width={400} height={500}
                            />
                        </div>
                    </div>
                    <div className="grid gap-4">
                        <div>
                            <img
                                className="h-auto max-w-full rounded-lg hover:rotate-3 hover:scale-110 duration-700  border-4 border-white hover:border-primary hover:border-4 lazyload"
                                data-src="./assets/pic7.png?v=123456"
                                alt="pic" width={400} height={500}
                            />
                        </div>
                        <div>
                            <img
                                className="h-auto max-w-full rounded-lg hover:-rotate-3 hover:scale-110 duration-700  border-4 border-white hover:border-primary hover:border-4 lazyload"
                                data-src="./assets/pic8.png?v=123456"
                                alt="pic" width={400} height={500}
                            />
                        </div>
                        <div>
                            <img
                                className="h-auto max-w-full rounded-lg hover:rotate-3 hover:scale-110 duration-700  border-4 border-white hover:border-primary hover:border-4 lazyload"
                                data-src="./assets/pic9.png?v=123456"
                                alt="pic" width={400} height={500}
                            />
                        </div>
                    </div>
                    <div className="grid gap-4">
                        <div>
                            <img
                                className="h-auto max-w-full rounded-lg hover:rotate-3 hover:scale-110 duration-700  border-4 border-white hover:border-primary hover:border-4 lazyload"
                                data-src="./assets/pic10.png?v=123456"
                                alt="pic" width={400} height={500}
                            />
                        </div>
                        <div>
                            <img
                                className="h-auto max-w-full rounded-lg hover:rotate-3 hover:scale-110 duration-700  border-4 border-white hover:border-primary hover:border-4 lazyload"
                                data-src="./assets/pic4.png?v=123456"
                                alt="pic" width={400} height={500}
                            />
                        </div>
                        <div>
                            <img
                                className="h-auto max-w-full rounded-lg hover:-rotate-3 hover:scale-110 duration-700  border-4 border-white hover:border-primary hover:border-4 lazyload"
                                data-src="./assets/pic3.png?v=123456"
                                alt="pic" width={400} height={500}
                            />
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )
}
