import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {  Autoplay } from 'swiper/modules';
import 'lazysizes';

export default function Slider(props) {
    if(props.products.length===0){
        return(
            <div></div>
        );
    }
  return (
    <div>
    <div className='2xl:max-w-[1400px] xl:max-w-[1000px]  lg:max-w-[900px] max-w-[90%] mx-auto'>
                <div className='w-full my-24'>
                    <div className='flex flex-col items-start justify-start'>
                    <h1 className='font-sand font-bold text-2xl text-black  '><span className='opacity-70'>{props.title}</span> <span className='text-primary opacity-100'>Products</span></h1>
                        <svg className='w-full mt-2 mb-6' width="full" height="auto" viewBox="0 0 1198 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line y1="2.5" x2="1198" y2="2.5" stroke="#CBB9B9"/>
                        <line x1="1.5" y1="1.5" x2="272.5" y2="1.5" stroke="#ED0F58" strokeWidth="3" strokeLinecap="round"/>
                        </svg>
                    </div>


                        <Swiper
                            spaceBetween={50}
                            slidesPerView={5}
                            autoplay={true}
                            className='select-none'
                            breakpoints={{ 200: { slidesPerView: 1 }, 450:{ slidesPerView: 2} , 800:{ slidesPerView: 3} , 1100:{ slidesPerView: 4}, 1400:{ slidesPerView: 5} }}
                            modules={[Autoplay]}

                            >
                                {
                                    props.products.map((e)=>{
                                        return(
                                            <SwiperSlide key={e.id} className='w-[250px]  border-[1px] rounded-lg border-[#c5c2c2]' style={{width:"250px!important", height: "600px!important"}}>
                                            <div className='w-full h-[180px]'>
                                            <img className='w-full h-full rounded-lg rounded-b-none object-cover lazyload' data-src={e.pic} alt="Tasty Pics"  />
                                            </div>
                                            <div className='p-2 px-4 h-[]'>
                                                <h1 className='font-sand font-bold text-[#909090] text-lg'>{e.cat}</h1>
                                                <h1 className='font-sand font-normal text-black text-2xl'>{e.name}</h1>
                                            </div>
                                        </SwiperSlide>
                                        )

                                    })
                                }



                        </Swiper>
                </div>
            </div>
        </div>
  )
}
