import { HiOutlineMail, HiOutlinePhone } from "react-icons/hi";
import {AiFillFacebook,AiFillInstagram,AiFillYoutube} from "react-icons/ai"




function Footer() {
    return (
        <div className="mt-48">
            <svg width="full" height="auto" viewBox="0 0 1920 288" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 0L64 12C128 24 256 48 384 84C512 120 640 168 768 156C896 144 1024 72 1152 72C1280 72 1408 144 1536 156C1664 168 1792 120 1856 96L1920 72V288H1856C1792 288 1664 288 1536 288C1408 288 1280 288 1152 288C1024 288 896 288 768 288C640 288 512 288 384 288C256 288 128 288 64 288H0V0Z" fill="#FAD7E4" />
            </svg>
            <div className="bg-secondery w-full h-fit pb-12 ">


                <div className="lg:max-w-[1400px] px-12 mx-auto flex  justify-evenly lg:flex-row flex-col gap-5">
                    <div className="flex items-start flex-col w-fit">
                        <div>
                            <h1 className="text-4xl font-monta font-bold uppercase">tasty food</h1>
                            <h2 className="text-lg font-monta font-light uppercase">best food on earth</h2>
                        </div>
                        <div className="my-4">
                            <h3 className="flex items-center justify-center gap-2 font-pop text-lg"><HiOutlineMail /> <span>contact@tasty.com</span></h3>
                            <h3 className="flex items-center justify-center gap-2 font-pop text-lg"><HiOutlinePhone /> (00216) 23 863 375</h3>
                        </div>
                    </div>
                    <div>
                    <div className="flex gap-4 md:flex-row flex-col ">
                        <div className="flex justify-start flex-col">
                            <h1 className="text-xl font-bold font-monta">General</h1>
                            <a href="https://test.com" className="text-lg font-normal font-monta">Home</a>
                            <a href="https://test.com" className="text-lg font-normal font-monta">Contact</a>
                            <a href="https://test.com" className="text-lg font-normal font-monta">Catalogue</a>
                        </div>
                        <div className="flex justify-start flex-col">
                            <h1 className="text-xl font-bold font-monta">Support</h1>
                            <a href="https://test.com" className="text-lg font-normal font-monta">Privacy Policy</a>
                            <a href="https://test.com" className="text-lg font-normal font-monta">Terms & Conditions</a>
                            <a href="https://test.com" className="text-lg font-normal font-monta">Cookies use</a>
                        </div>
                        <div className="flex justify-start flex-col">
                            <h1 className="text-xl font-bold font-monta">Follow Us</h1>
                            <div className="flex gap-2 items-center">
                            <a href="https://www.facebook.com/TastyGabes" className="text-3xl font-normal font-monta text-primary">  <AiFillFacebook/> </a>
                            <a href="https://www.instagram.com/tasty_rolled_ice_cream" className="text-3xl font-normal font-monta text-primary"> <AiFillInstagram/> </a>
                            <a href="https://test.com" className="text-3xl font-normal font-monta text-primary"> <AiFillYoutube/> </a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

            </div>
        </div>
    );
}

export default Footer;
