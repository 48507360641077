import { HiOutlineMail, HiOutlinePhone } from "react-icons/hi";
import {AiFillFacebook,AiFillInstagram} from "react-icons/ai"

import {GrLocation} from "react-icons/gr"


export default function Section5(){
    return(
       <div className="mx-auto flex justify-center items-center flex-col 2xl:max-w-[1400px] xl:max-w-[1200px]  max-sm:max-w-[100%] " id="findus">
                <div className="my-6 mt-12 w-full flex flex-col justify-center items-center">

        <h1 className="font-sand font-bold text-4xl text-center my-6">Tasty <span className="text-primary">rolled ice cream</span></h1>
        <iframe className="w-full" title="myFrame" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4685.167842976383!2d10.108040516084463!3d33.86610838550044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12556e4dc8445541%3A0x1074aca23a1eba80!2sTasty%20ice!5e0!3m2!1sen!2stn!4v1693235110121!5m2!1sen!2stn" width="full" height="400"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        <h1 className=" text-2xl text-center my-6 flex items-center gap-4 border-b-4 border-prim3     mt-4 p-4 px-12"> <GrLocation className="lg:text-xl text-4xl"/>  <span className=" font-ops  lg:text-2xl text-xl">نهج إبن خلدون, Teboulbou, Gabès, Tunisia</span></h1>

        </div>
        <div className="my-6 w-full flex flex-col justify-center items-center">

        <h1 className="font-sand font-bold text-4xl text-center my-6">Tasty <span className="text-primary">Lounge</span></h1>
        <iframe className="w-full" title="myFrame" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.7403795134765!2d10.11994587549781!3d33.87058112730108!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12556f8a6c6df1ad%3A0xc61c0dac92af16de!2sTasty%20Lounge!5e0!3m2!1sen!2stn!4v1692975241270!5m2!1sen!2stn" width="full" height="400" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        <h1 className=" text-2xl text-center my-6 flex items-center gap-4 border-b-4 border-prim3 mt-4 p-4 px-12"> <GrLocation className="lg:text-xl text-4xl"/>  <span className="lg:text-2xl text-xl font-ops ">450 Ibn Khaldoun S1 Cite Ezzouhour, Gabès, Tunisia</span></h1>

        </div>

        <div className="my-10 w-full">
        <div className="flex items-center justify-center flex-wrap gap-x-20 w-[90%]  mx-auto">
        <h1 className="lg:w-[40%] w-full justify-center  text-2xl text-center my-6 flex items-center gap-4 border-b-4 border-prim3 mt-4 p-4 lg:px-12"> <HiOutlineMail className=" text-2xl"/>  <span className="lg:text-2xl text-xl font-ops ">contact@Tasty.tn</span></h1>
        <h1 className="lg:w-[40%] w-full justify-center text-2xl text-center my-6 flex items-center gap-4 border-b-4 border-prim3 mt-4 p-4 lg:px-12"> <HiOutlinePhone className=" text-2xl"/>  <span className="lg:text-2xl text-xl font-ops ">(+216) 25 510 349</span></h1>
        </div>
        <div className="flex items-center justify-center flex-wrap gap-x-20 w-[90%] mx-auto">
        <a href="https://www.facebook.com/TastyGabes" className="lg:w-[40%] w-full justify-center  text-2xl text-center my-6 flex items-center gap-4 border-b-4 border-prim3 mt-4 p-4 lg:px-12"> <AiFillFacebook className="text-2xl"/>  <span className="lg:text-2xl text-xl font-ops ">@TastyGabes</span></a>
        <a href="https://www.instagram.com/tasty_rolled_ice_cream" className="lg:w-[40%] w-full justify-center  text-2xl text-center my-6 flex items-center gap-4 border-b-4 border-prim3 mt-4 p-4 lg:px-12"> <AiFillInstagram className="text-2xl"/>  <span className="lg:text-2xl text-xl font-ops ">@tasty_rolled_ice_cream</span></a>
        </div>
        </div>
       </div>

    )
}
