import { useState } from "react";
import MainTitle from "./mainTitle";
import { RiVideoLine } from "react-icons/ri";

import 'lazysizes';

export default function Section4(){
    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
      };
    const [loaded, setLoaded] = useState(false);
    const handleLoad = () => {
        openInNewTab("https://www.instagram.com/reel/C15NoiiiFoH/")
        //setLoaded(true);

      };
      if (!loaded) {
        return (
            <div className="w-full flex gap-8 bg-prim3 lg:py-24 py-12 flex-col cut" id="video">
            <MainTitle title={"Discover our trailer"} color={"white"} />
            <div className="facade-placeholder w-full bg-black h-96 mt-8 flex justify-center items-center" onClick={handleLoad}>
                <span className="font-bold uppercase font-monta text-primary text-xl cursor-pointer flex  items-center gap-2"><RiVideoLine className="text-3xl" />  <span className="text-white">Click to watch</span> video</span>
            </div>

            </div>

        );
      }else{
        return (
            <div className="w-full flex gap-8 bg-prim3 lg:py-24 py-12 flex-col cut" id="video">
            <MainTitle title={"Discover our trailer"} color={"white"} />
            <iframe width="full" height="700" className="mt-12 lazyload" src="https://www.youtube.com/embed/tJlzIJaokVY?si=LILHUbRcXOIKUOZ0&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          );
      }
}
