import { useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import Slider from './Slider';
import 'lazysizes';
function Catalogue(props) {
    let products_TREND;
    let products_SWEET;
    let products_SALTY;
    let products_CIC;
    let products_JD;
    let products_MSM;
    let products_CGP;
    let products_Burg_Tac_Tur;
    let products_Pla;

    const location = useLocation();
    let name;
    const word = location.search.substring(location.search.indexOf("?name=")+6,(location.search.indexOf("&")===-1) ? undefined : location.search.indexOf("&"))
    let products_BF;
    if(word==="tastylounge"){
        //Tasty Lounge Data
        name = "Tasty Lounge"
         products_TREND = [
            {
                "pic": "./assets/TL/Breakfast/1.png",
                "cat":"Breakfast",
                "name":"Express",
            },
            {
                "pic": "./assets/TL/CGP/ES_Pancakes.png",
                "cat":"Pancakes",
                "name":"Pancake chocolat",
            },
            {
                "pic": "./assets/TL/CGP/Crepe.png",
                "cat":"Crepe",
                "name":"Crepe",
            },
            {
                "pic": "./assets/TL/Tac/2.png",
                "cat":"Tacos",
                "name":"Chawarma",
            },
            {
                "pic": "./assets/TL/Pla/1.png",
                "cat":"Plats",
                "name":"Nugut's",
            },
            {
                "pic": "./assets/TL/Tac/3.png",
                "cat":"Tacos",
                "name":"Chicken XL",
            },
            {
                "pic": "./assets/TL/Pla/5.png",
                "cat":"Plats",
                "name":"Salade Cesar",
            },
            {
                "pic": "./assets/TL/Burg/1.png",
                "cat":"Burger",
                "name":"Chicken Burger",
            },
            {
                "pic": "./assets/TL/JD/6.png",
                "cat":"Drinks",
                "name":"Infusion",
            },
            {
                "pic": "./assets/TL/Pla/3.png",
                "cat":"Plats",
                "name":"Escalope",
            },
            {
                "pic": "./assets/TL/JD/10.png",
                "cat":"Drinks",
                "name":"Eau 0.5L",
            },
            {
                "pic": "./assets/TL/MSM/2.png",
                "cat":"Mojito",
                "name":"Blue",
            },
            {
                "pic": "./assets/TL/MSM/5.png",
                "cat":"Mojito",
                "name":"Pinacolada",
            },
        ]
        products_BF = [
            {
                "pic": "./assets/TL/Breakfast/1.png",
                "cat":"Breakfast",
                "name":"Express",
            },
            {
                "pic": "./assets/TL/Breakfast/2.png",
                "cat":"Breakfast",
                "name":"Sale",
            },
            {
                "pic": "./assets/TL/Breakfast/3.png",
                "cat":"Breakfast",
                "name":"Sucre",
            },
            {
                "pic": "./assets/TL/Breakfast/4.png",
                "cat":"Breakfast",
                "name":"Mixte",
            },
            {
                "pic": "./assets/TL/Breakfast/5.png",
                "cat":"Breakfast",
                "name":"Tasty",
            },
        ]
        products_CIC = [
            {
                "pic": "./assets/TL/CIC/1.png",
                "cat":"Coffee",
                "name":"Express",
            },
            {
                "pic": "./assets/TL/CIC/2.png",
                "cat":"Coffee",
                "name":"Capucin",
            },
            {
                "pic": "./assets/TL/CIC/3.png",
                "cat":"Coffee",
                "name":"Cafe Creme",
            },
            {
                "pic": "./assets/TL/CIC/4.png",
                "cat":"Coffee",
                "name":"Cappuccino",
            },
            {
                "pic": "./assets/TL/CIC/5.png",
                "cat":"Coffee",
                "name":"Frappuccino",
            },
            {
                "pic": "./assets/TL/CIC/6.png",
                "cat":"Coffee",
                "name":"Americian",
            },
            {
                "pic": "./assets/TL/CIC/7.png",
                "cat":"Iced Coffee",
                "name":"Classic",
            },

        ]
        products_JD = [
            {
                "pic": "./assets/TL/JD/1.png",
                "cat":"Juice",
                "name":"Citronnade",
            },
            {
                "pic": "./assets/TL/JD/2.png",
                "cat":"Juice",
                "name":"Orange",
            },
            {
                "pic": "./assets/TL/JD/3.png",
                "cat":"Juice",
                "name":"Fraise",
            },
            {
                "pic": "./assets/TL/JD/4.png",
                "cat":"Juice",
                "name":"Kiwi",
            },
            {
                "pic": "./assets/TL/JD/5.png",
                "cat":"Juice",
                "name":"Banane",
            },
            {
                "pic": "./assets/TL/JD/6.png",
                "cat":"Drinks",
                "name":"Infusion",
            },
            {
                "pic": "./assets/TL/JD/7.png",
                "cat":"Drinks",
                "name":"Chocolat chaud",
            },
            {
                "pic": "./assets/TL/JD/8.png",
                "cat":"Drinks",
                "name":"Chocolat Glacee",
            },
            {
                "pic": "./assets/TL/JD/9.png",
                "cat":"Drinks",
                "name":"Soda",
            },
            {
                "pic": "./assets/TL/JD/10.png",
                "cat":"Drinks",
                "name":"Eau 0.5L",
            },
            {
                "pic": "./assets/TL/JD/10.png",
                "cat":"Drinks",
                "name":"Eau 1L",
            },

        ]
        products_MSM = [
            {
                "pic": "./assets/TL/MSM/1.png",
                "cat":"Mojito",
                "name":"Classique",
            },
            {
                "pic": "./assets/TL/MSM/2.png",
                "cat":"Mojito",
                "name":"Blue",
            },
            {
                "pic": "./assets/TL/MSM/3.png",
                "cat":"Mojito",
                "name":"Framboise",
            },
            {
                "pic": "./assets/TL/MSM/4.png",
                "cat":"Mojito",
                "name":"Cerise",
            },
            {
                "pic": "./assets/TL/MSM/5.png",
                "cat":"Mojito",
                "name":"Pinacolada",
            },
            {
                "pic": "./assets/TL/MSM/6.png",
                "cat":"Mojito",
                "name":"Galaxy",
            },
            {
                "pic": "./assets/TL/MSM/7.png",
                "cat":"Smoothie",
                "name":"Fruits Au Choix",
            },
            {
                "pic": "./assets/TL/MSM/8.png",
                "cat":"Smoothie",
                "name":"Chocolat Au Choix",
            },
            {
                "pic": "./assets/TL/MSM/9.png",
                "cat":"Smoothie",
                "name":"Speculos",
            },
            {
                "pic": "./assets/TL/MSM/10.png",
                "cat":"Milkshake",
                "name":"Fruits Au Choix",
            },
            {
                "pic": "./assets/TL/MSM/11.png",
                "cat":"Milkshake",
                "name":"Chocolat Au Choix",
            },
            {
                "pic": "./assets/TL/MSM/12.png",
                "cat":"Milkshake",
                "name":"Speculos",
            },


        ]
        products_CGP = [
            {
                "pic": "./assets/TL/CGP/ES_BouleAboule.png",
                "cat":"Glace",
                "name":"Boule a boule",
            },
            {
                "pic": "./assets/TL/CGP/ES_gaufre1.png",
                "cat":"Gaufre",
                "name":"Chocolat",
            },
            {
                "pic": "./assets/TL/CGP/ES_gaufre2.png",
                "cat":"Gaufre",
                "name":"Speculoos",
            },

            {
                "pic": "./assets/TL/CGP/ES_Pancakes.png",
                "cat":"Pancakes",
                "name":"Pancake chocolat",
            },
            {
                "pic": "./assets/TL/CGP/ES_prepe1.png",
                "cat":"Crepe",
                "name":"Crepe chocolat",
            },
            {
                "pic": "./assets/TL/CGP/ES_prepe2.png",
                "cat":"Crepe",
                "name":"Crepe Fruits",
            },
            {
                "pic": "./assets/TL/CGP/ES_prepe3.png",
                "cat":"Crepe",
                "name":"Crepe Speculoos",
            },
            {
                "pic": "./assets/TL/CGP/crepePanee.png",
                "cat":"Crepe",
                "name":"Crepe Panee",
            },
            {
                "pic": "./assets/TL/CGP/Crepe.png",
                "cat":"Crepe",
                "name":"Crepe",
            },



        ]
        products_Burg_Tac_Tur = [
            {
                "pic": "./assets/TL/Burg/1.png",
                "cat":"Burger",
                "name":"Chicken Burger",
            },
            {
                "pic": "./assets/TL/Burg/2.png",
                "cat":"Burger",
                "name":"Chicken Panee Burger",
            },
            {
                "pic": "./assets/TL/Tac/1.png",
                "cat":"Tacos",
                "name":"Chicken M",
            },
            {
                "pic": "./assets/TL/Tac/2.png",
                "cat":"Tacos",
                "name":"Chawarma",
            },
            {
                "pic": "./assets/TL/Tac/3.png",
                "cat":"Tacos",
                "name":"Chicken XL",
            },
            {
                "pic": "./assets/TL/Tur/1.png",
                "cat":"Turk",
                "name":"Chicken M",
            },
            {
                "pic": "./assets/TL/Tur/2.png",
                "cat":"Turk",
                "name":"Chawarma",
            },
            {
                "pic": "./assets/TL/Tur/3.png",
                "cat":"Turk",
                "name":"Chicken XL",
            },

        ]

        products_Pla = [
            {
                "pic": "./assets/TL/Pla/1.png",
                "cat":"Plats",
                "name":"Nugut's",
            },
            {
                "pic": "./assets/TL/Pla/2.png",
                "cat":"Plats",
                "name":"Escalope Panee",
            },
            {
                "pic": "./assets/TL/Pla/3.png",
                "cat":"Plats",
                "name":"Escalope",
            },
            {
                "pic": "./assets/TL/Pla/4.png",
                "cat":"Plats",
                "name":"Mixte",
            },
            {
                "pic": "./assets/TL/Pla/5.png",
                "cat":"Plats",
                "name":"Salade Cesar",
            },

        ]

    }else{
        //Tasty Ice Cream
        name = "Tasty Ice Cream"
        products_TREND = [
            {
                "pic": "./assets/TIC/Waffle/1.png",
                "cat":"Waffle",
                "name":"Waffle Sucree",
            },
            {
                "pic": "./assets/TIC/Waffle/2.png",
                "cat":"Waffle",
                "name":"Waffle Sale",
            },
            {
                "pic": "./assets/TIC/Breakfast/3.png",
                "cat":"Breakfast",
                "name":"Petit dej tasty",
            }
            ,
            {
                "pic": "./assets/TIC/Jwajem/3.png",
                "cat":"Jwajem",
                "name":"Grand naturel chocolat",
            },
            {
                "pic": "./assets/TIC/Jwajem/4.png",
                "cat":"Jwajem",
                "name":"Chantily oreo speculoos",
            },

            {
                "pic": "./assets/TL/CGP/ES_prepe3.png",
                "cat":"Crepe",
                "name":"Crepe Speculoos",
            },
            {
                "pic": "./assets/TL/CGP/Crepe.png",
                "cat":"Crepe",
                "name":"Crepe",
            },
        ]
        products_BF = [
            {
                "pic": "./assets/TIC/Breakfast/1.png",
                "cat":"Breakfast",
                "name":"9hiwet esbeh",
            },
            {
                "pic": "./assets/TIC/Breakfast/2.png",
                "cat":"Breakfast",
                "name":"Petit dej aux choix",
            },
            {
                "pic": "./assets/TIC/Breakfast/3.png",
                "cat":"Breakfast",
                "name":"Petit dej tasty",
            }

        ]
        products_CIC = [
            {
                "pic": "./assets/TL/CIC/1.png",
                "cat":"Coffee",
                "name":"Express",
            },
            {
                "pic": "./assets/TL/CIC/2.png",
                "cat":"Coffee",
                "name":"Capucin",
            },
            {
                "pic": "./assets/TL/CIC/3.png",
                "cat":"Coffee",
                "name":"Cafe Creme",
            },
            {
                "pic": "./assets/TL/CIC/4.png",
                "cat":"Coffee",
                "name":"Cappuccino",
            },
            {
                "pic": "./assets/TL/CIC/5.png",
                "cat":"Coffee",
                "name":"Frappuccino",
            },
            {
                "pic": "./assets/TL/CIC/6.png",
                "cat":"Coffee",
                "name":"Americian",
            },{
                "pic": "./assets/TIC/Rolled/2.png",
                "cat":"chocolat chaud",
                "name":"chocolat chaud",
            },


        ]
        products_JD = [
            {
                "pic": "./assets/TL/JD/1.png",
                "cat":"Juice",
                "name":"Citronnade",
            },
            {
                "pic": "./assets/TL/JD/2.png",
                "cat":"Juice",
                "name":"Orange",
            },
            {
                "pic": "./assets/TL/JD/3.png",
                "cat":"Juice",
                "name":"Fraise",
            },
            {
                "pic": "./assets/TL/MSM/2.png",
                "cat":"Mojito",
                "name":"Blue",
            },

            {
                "pic": "./assets/TL/MSM/10.png",
                "cat":"Milkshake",
                "name":"Chocolat farise",
            },
            {
                "pic": "./assets/TL/MSM/8.png",
                "cat":"Smoothie",
                "name":"Nutella oreo smoothie",
            },
            {
                "pic": "./assets/TL/JD/9.png",
                "cat":"Drinks",
                "name":"Soda",
            },
            {
                "pic": "./assets/TL/JD/10.png",
                "cat":"Drinks",
                "name":"Eau 0.5L",
            },
            {
                "pic": "./assets/TL/JD/10.png",
                "cat":"Drinks",
                "name":"Eau 1L",
            },

        ]
        products_MSM = [
            {
                "pic": "./assets/TIC/Rolled/1.png",
                "cat":"yaourt glace",
                "name":"yaourt glace",
            },

            {
                "pic": "./assets/TIC/Rolled/3.png",
                "cat":"Rolled ice",
                "name":"chocolat",
            },
            {
                "pic": "./assets/TIC/Rolled/4.png",
                "cat":"Rolled ice",
                "name":"Cafe fruit",
            },
            {
                "pic": "./assets/TIC/Jwajem/1.png",
                "cat":"Jwajem",
                "name":"Petit naturel chocolat",
            },
            {
                "pic": "./assets/TIC/Jwajem/2.png",
                "cat":"Jwajem",
                "name":"Moyenne naturel chocolat",
            },
            {
                "pic": "./assets/TIC/Jwajem/3.png",
                "cat":"Jwajem",
                "name":"Grand naturel chocolat",
            },
            {
                "pic": "./assets/TIC/Jwajem/4.png",
                "cat":"Jwajem",
                "name":"Chantily oreo speculoos",
            },

        ]
        products_CGP = [
            {
                "pic": "./assets/TIC/Waffle/1.png",
                "cat":"Waffle",
                "name":"Waffle Sucree",
            },
            {
                "pic": "./assets/TIC/Waffle/2.png",
                "cat":"Waffle",
                "name":"Waffle Sale",
            },
            {
                "pic": "./assets/TL/CGP/ES_BouleAboule.png",
                "cat":"Glace",
                "name":"Boule a boule",
            },

            {
                "pic": "./assets/TL/CGP/ES_gaufre2.png",
                "cat":"Gaufre",
                "name":"Speculoos",
            },

            {
                "pic": "./assets/TL/CGP/ES_Pancakes.png",
                "cat":"Pancakes",
                "name":"Pancake chocolat",
            },
            {
                "pic": "./assets/TL/CGP/ES_prepe1.png",
                "cat":"Crepe",
                "name":"Crepe chocolat",
            },
            {
                "pic": "./assets/TL/CGP/ES_prepe2.png",
                "cat":"Crepe",
                "name":"Crepe Fruits",
            },
            {
                "pic": "./assets/TL/CGP/ES_prepe3.png",
                "cat":"Crepe",
                "name":"Crepe Speculoos",
            },

            {
                "pic": "./assets/TL/CGP/Crepe.png",
                "cat":"Crepe",
                "name":"Crepe",
            },



        ]
        products_Burg_Tac_Tur = [
            {
                "pic": "./assets/TIC/Malfouf/1.png",
                "cat":"Malfouf",
                "name":"Malfouf",
            },

        ]

        products_Pla = [


        ]

    }
    useEffect(() => {
        document.title = name +" Catalogue";
      }, [name]);

  return (
    <div>
            <div className='relative w-full h-fit   '>
                <h1 className='z-20 relative w-full mx-auto text-center 2xl:pt-12 lg:pt-8 pt-6 bg-primary text-white font-sand font-bold text-6xl'>CATALOGUE</h1>
                <h1 className='z-20 relative w-full mx-auto text-center pt-2 bg-primary text-white font-sand font-light text-3xl uppercase'>{name}</h1>
                <svg className='  w-full h-auto' width="full" height="auto"  viewBox="0 0 1920 166" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 102.843L80 111.527C160 120.212 320 137.58 480 134.685C640 131.791 800 108.633 960 114.422C1120 120.212 1280 154.949 1440 163.633C1600 172.317 1760 154.949 1840 146.265L1920 137.58V16H1840C1760 16 1600 16 1440 16C1280 16 1120 16 960 16C800 16 640 16 480 16C320 16 160 16 80 16H0V102.843Z" fill="#44BDF1"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M0 94.843L80 103.527C160 112.212 320 129.58 480 126.685C640 123.791 800 100.633 960 106.422C1120 112.212 1280 146.949 1440 155.633C1600 164.317 1760 146.949 1840 138.265L1920 129.58V8H1840C1760 8 1600 8 1440 8C1280 8 1120 8 960 8C800 8 640 8 480 8C320 8 160 8 80 8H0V94.843Z" fill="#F9DB07"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M0 94.843L80 103.527C160 112.212 320 129.58 480 126.685C640 123.791 800 100.633 960 106.422C1120 112.212 1280 146.949 1440 155.633C1600 164.317 1760 146.949 1840 138.265L1920 129.58V8H1840C1760 8 1600 8 1440 8C1280 8 1120 8 960 8C800 8 640 8 480 8C320 8 160 8 80 8H0V94.843Z" fill="#F9DB07"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M0 86.843L80 95.5273C160 104.212 320 121.58 480 118.685C640 115.791 800 92.6326 960 98.4221C1120 104.212 1280 138.949 1440 147.633C1600 156.317 1760 138.949 1840 130.265L1920 121.58V3.48831e-06H1840C1760 3.48831e-06 1600 3.48831e-06 1440 3.48831e-06C1280 3.48831e-06 1120 3.48831e-06 960 3.48831e-06C800 3.48831e-06 640 3.48831e-06 480 3.48831e-06C320 3.48831e-06 160 3.48831e-06 80 3.48831e-06H0V86.843Z" fill="#ED0F58"/>
                </svg>
            </div>


            <Slider products={products_TREND} title="Our Trendy "/>
            <Slider products={products_BF} title="Tasty Breakfast "/>
            <Slider products={products_CIC} title="Tasty Coffee & chocolat chaud "/>
            <Slider products={products_JD} title="Tasty Juice & Milkshake"/>
            <Slider products={products_MSM} title="Tasty Rolled ice & Jwajem"/>
            <Slider products={products_CGP} title="Tasty Waffle & Crepes"/>
            <Slider products={products_Burg_Tac_Tur} title="Tasty Malfouf "/>
            <Slider products={products_Pla} title="Tasty Plats "/>
    </div>
  );
}

export default Catalogue;
