import Card from './Card_Section3';
import {FaMotorcycle} from "react-icons/fa";
import {MdHighQuality} from "react-icons/md"


export default function Section3(){
    const Smile = ()=>{
        return(
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40 2.5C19.29 2.5 2.5 19.2925 2.5 39.995C2.5 60.7075 19.29 77.5 40 77.5C60.7075 77.5 77.5 60.7075 77.5 39.995C77.5 19.285 60.7075 2.5 40 2.5ZM67.685 61.775C67.0442 62.4276 66.2789 62.9449 65.4343 63.2959C64.5898 63.647 63.6833 63.8248 62.7688 63.8188C59.805 63.8188 56.5612 62.1412 53.3875 58.9675C52.1462 57.7262 51.0425 56.62 50.53 55.965C52.535 55.0451 54.3977 53.8424 56.0612 52.3938L65.5487 59.64L58.2963 50.1388C59.7335 48.4559 60.8884 46.5512 61.7163 44.4987C62.34 44.9375 63.53 46.125 64.8775 47.4738C70.1538 52.7513 71.2287 58.2312 67.685 61.775ZM66.6475 45.705C64.5638 43.6225 63.4437 42.5063 62.4762 42.0625C62.5275 40.7888 60.8288 40.3013 60.1375 41.2738C54.7275 48.9075 48.3237 50.83 40 50.83C31.6763 50.83 25.2725 48.9088 19.8612 41.2738C19.1075 40.2113 17.1238 40.88 17.5625 42.4225C20.4038 52.4287 30.0625 58.2562 40 58.2562C42.7715 58.2515 45.5236 57.7938 48.1475 56.9012C48.6813 57.7912 49.77 58.8813 51.6213 60.7338C55.0575 64.1688 58.6713 66.06 62.1175 66.275C56.135 71.3225 48.42 74.375 40 74.375C21.045 74.375 5.625 58.9525 5.625 39.995C5.625 21.0438 21.045 5.625 40 5.625C58.9538 5.625 74.375 21.0438 74.375 39.995C74.375 44.7325 73.405 49.245 71.6638 53.355C70.9113 50.985 69.3413 48.4012 66.6475 45.705Z" fill="black"/>
        <path d="M26.3315 21.4824C22.8302 21.4824 19.329 24.6799 17.0065 31.0687C16.7765 31.7124 17.974 32.8724 18.574 32.2412C20.8265 29.8649 23.5202 28.9187 26.3327 28.9187C29.1452 28.9187 31.8377 29.8649 34.0915 32.2412C34.6902 32.8724 35.889 31.7112 35.659 31.0687C33.334 24.6787 29.8327 21.4824 26.3315 21.4824ZM53.669 21.4824C50.1677 21.4824 46.6665 24.6799 44.3427 31.0687C44.1127 31.7124 45.3115 32.8724 45.9102 32.2412C48.164 29.8649 50.8565 28.9187 53.669 28.9187C56.4815 28.9187 59.174 29.8649 61.4277 32.2412C62.0265 32.8724 63.2252 31.7112 62.9952 31.0687C60.6702 24.6787 57.169 21.4824 53.669 21.4824Z" fill="black"/>
        </svg>

        )
    }
    const Friendly = ()=>{
        return(
            <svg width="80" height="80" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M45.0002 53.3608C38.8559 53.3608 36.5252 50.6983 35.4434 49.2021M34.8359 41.329C34.8098 39.9412 34.2401 38.619 33.2494 37.6468C32.2587 36.6745 30.9261 36.1299 29.538 36.1299C28.15 36.1299 26.8174 36.6745 25.8267 37.6468C24.836 38.619 24.2663 39.9412 24.2402 41.329M29.5371 69.0846C32.7284 69.3358 32.2409 77.6946 28.1646 77.2371C24.0884 76.7796 24.7952 68.7096 29.5371 69.0846Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.1589 16.6908C19.9455 14.9042 20.6038 12.6657 19.6292 11.6911C18.6546 10.7165 16.4162 11.3748 14.6295 13.1615C12.8429 14.9481 12.1846 17.1866 13.1592 18.1612C14.1338 19.1358 16.3722 18.4775 18.1589 16.6908Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M27.1831 22.3161C24.8771 18.6276 22.3539 15.0795 19.6269 11.6904M23.8869 25.4998C21.0181 22.3179 13.1562 18.1611 13.1562 18.1611M44.9994 53.3604C51.1437 53.3604 53.4744 50.6979 54.5562 49.2017M55.1637 41.3286C55.1899 39.9407 55.7595 38.6186 56.7502 37.6464C57.7409 36.6741 59.0735 36.1295 60.4616 36.1295C61.8496 36.1295 63.1822 36.6741 64.1729 37.6464C65.1636 38.6186 65.7333 39.9407 65.7594 41.3286M44.9994 81.5623C54.5562 81.5623 55.6363 78.4836 55.6363 76.4867C55.6363 74.4898 53.5569 67.8092 44.9994 67.8092C36.4419 67.8092 34.3625 74.4936 34.3625 76.4904C34.3625 78.4873 35.4425 81.5623 44.9994 81.5623ZM60.4625 69.0842C57.2713 69.3354 57.7587 77.6942 61.835 77.2367C65.9112 76.7792 65.2044 68.7092 60.4625 69.0842Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M76.8441 18.1614C77.8187 17.1868 77.1604 14.9484 75.3737 13.1617C73.5871 11.3751 71.3487 10.7168 70.3741 11.6914C69.3994 12.666 70.0577 14.9044 71.8444 16.6911C73.631 18.4777 75.8695 19.136 76.8441 18.1614Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M62.8164 22.3161C65.1224 18.6276 67.6456 15.0795 70.3727 11.6904M66.1127 25.4998C68.9814 22.3179 76.8433 18.1611 76.8433 18.1611" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M53.1226 16.7756H53.1695C54.3219 16.0656 55.2224 15.0117 55.7438 13.7625C53.0459 13.7542 50.3783 14.3312 47.9251 15.4537C50.0165 13.2449 51.9607 10.9011 53.7451 8.4375C53.7451 8.4375 42.0995 11.3494 38.162 16.3744H38.192C22.0388 20.7937 12.7051 40.26 12.7051 51.6131C12.7051 64.4813 36.2551 67.8075 45.0001 67.8075C53.7451 67.8075 77.2951 64.4813 77.2951 51.6131C77.2951 40.5713 68.4657 21.8625 53.1226 16.7756Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

        )
    }
    return(
        <div className="w-full flex gap-8 bg-primary-center">
        <div className="2xl:max-w-[1300px] xl:max-w-[1200px] max-w-[90%] mx-auto flex justify-center lg:py-24 py-12 flex-col lg:flex-row lg:gap-5 flex-wrap gap-4 ">
                <Card title="Fast delivery" icon={<FaMotorcycle/>}/>
                <Card title="High Quality" icon={<MdHighQuality/>}/>
                <Card title="Delicious food" icon={<Smile/>}/>
                <Card title="friendly STUFF" icon={<Friendly/>}/>
          </div>
        </div>
    )
}
