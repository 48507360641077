import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './pages/home/Home';
import Catalogue from './pages/catalogue/Catalogue';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import NavBar from './Components/navBar/NavBar';
import Footer from './Components/Footer/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>

        <NavBar/>
        <Routes>
                <Route exact path='/'  element={<Home />} />
                <Route path='/catalogue' element={<Catalogue  />} />
        </Routes>
            <Footer/>
    </BrowserRouter>
  </React.StrictMode>
);

