import { TypeAnimation } from 'react-type-animation';

import { RiVideoLine } from "react-icons/ri";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';
import "swiper/css";
import 'lazysizes';
import { useEffect } from 'react';


function Hero() {
    useEffect(() => {
        // Create a new Image object to preload the image
        const image = new Image();
        const image2 = new Image();
        image.src = "./assets/bg1.png?v=123456";
        image2.src = "./assets/bg2.png?v=123456";

        // Once the image is loaded, it will be cached by the browser

      }, []);
    return (
        <div className="w-full bg-secondery   2xl:h-[500px] lg:h-[400px]  h-fit py-40 lg:py-0 flex relative">
            <div className="lg:w-[56%] w-full flex justify-center items-center">
                <div className="flex w-full 2xl:w-[75%] lg:w-[30vw] flex-col gap-3 pl-10 ">
                    <TypeAnimation className="font-sand font-bold text-4xl uppercase"
                        sequence={[
                            // Same substring at the start will only be typed out once, initially
                            'Explore Flavors: at Tasty Lounge',
                            1000, // wait 1s before replacing "Mice" with "Hamsters"
                            'Explore Flavors: at Tasty Rolled Ice Cream',
                            1000,

                        ]}
                        wrapper="span"
                        speed={25}
                        style={{ fontSize: '2.25rem', fontWeight: 'bold', display: 'inline-block' }}
                        repeat={Infinity}
                        preRenderFirstString={true}
                    />

                    <div className="flex gap-3">
                        <a href="#findus" className="bg-prim1 border-primary border-1 uppercase font-ops font-black px-6 py-1 text-white text-lg">Find Us</a>
                        <a href="#video" className=" uppercase font-ops font-bold text-primary flex items-center gap-2 text-lg"> <RiVideoLine />  Watch Video</a>
                    </div>
                </div>
            </div>
            <div className="relative h-fill-av  w-[65%]  hidden lg:block">
                <div className="absolute h-full z-10 w-[25%]">
                    <svg className="w-fit" width="300" height="auto" viewBox="0 0 300 566" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M285.753 0L290.742 23.5833C295.731 47.1667 305.709 94.3333 295.731 141.5C285.753 188.667 255.819 235.833 225.885 283C195.951 330.167 166.018 377.333 161.029 424.5C156.04 471.667 175.996 518.833 185.973 542.417L195.951 566H16.3488V542.417C16.3488 518.833 16.3488 471.667 16.3488 424.5C16.3488 377.333 16.3488 330.167 16.3488 283C16.3488 235.833 16.3488 188.667 16.3488 141.5C16.3488 94.3333 16.3488 47.1667 16.3488 23.5833V0L285.753 0Z" fill="#FF5D93" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M274.308 0L279.297 23.5833C284.286 47.1667 294.264 94.3333 284.286 141.5C274.308 188.667 244.375 235.833 214.441 283C184.507 330.167 154.573 377.333 149.584 424.5C144.595 471.667 164.551 518.833 174.529 542.417L184.507 566H4.90445V542.417C4.90445 518.833 4.90445 471.667 4.90445 424.5C4.90445 377.333 4.90445 330.167 4.90445 283C4.90445 235.833 4.90445 188.667 4.90445 141.5C4.90445 94.3333 4.90445 47.1667 4.90445 23.5833V0L274.308 0Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M269.404 0L274.393 23.5833C279.382 47.1667 289.36 94.3333 279.382 141.5C269.404 188.667 239.47 235.833 209.537 283C179.603 330.167 149.669 377.333 144.68 424.5C139.691 471.667 159.647 518.833 169.625 542.417L179.603 566H0.000149674V542.417C0.000149674 518.833 0.000149674 471.667 0.000149674 424.5C0.000149674 377.333 0.000149674 330.167 0.000149674 283C0.000149674 235.833 0.000149674 188.667 0.000149674 141.5C0.000149674 94.3333 0.000149674 47.1667 0.000149674 23.5833V0L269.404 0Z" fill="#FAD7E4" />
                    </svg>
                </div>

                <Swiper className=" h-fill-av   w-fill-av "
                    autoplay={true}
                    modules={[Autoplay]}

                    >

                    <SwiperSlide ><img data-src="./assets/bg1.png?v=123456" className="h-auto max-w-full object-cover brightness-75 lazyload" alt="Tasty Lounge"  width={1300} height={500} /></SwiperSlide>
                    <SwiperSlide><img data-src="./assets/bg2.png?v=123456" className="h-auto max-w-full object-cover brightness-75 lazyload" alt="Tasty Rolled Ice Cream"   width={1300} height={500} /></SwiperSlide>

                </Swiper>
            </div>
            <div className="absolute top-20 left-24  lg:block">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_613_46)">
                        <path d="M30 0L31.782 28.218L60 30L31.782 31.782L30 60L28.218 31.782L0 30L28.218 28.218L30 0Z" fill="url(#paint0_linear_613_46)" />
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_613_46" x1="6.15" y1="4.8" x2="30" y2="60" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FF0356" />
                            <stop offset="1" stopColor="#C0E8FF" />
                        </linearGradient>
                        <clipPath id="clip0_613_46">
                            <rect width="60" height="60" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            </div>
            <div className="absolute bottom-6 lg:left-[35rem]">

                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_613_46)">
                        <path d="M30 0L31.782 28.218L60 30L31.782 31.782L30 60L28.218 31.782L0 30L28.218 28.218L30 0Z" fill="url(#paint0_linear_613_46)" />
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_613_46" x1="6.15" y1="4.8" x2="30" y2="60" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FF0356" />
                            <stop offset="1" stopColor="#C0E8FF" />
                        </linearGradient>
                        <clipPath id="clip0_613_46">
                            <rect width="60" height="60" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            </div>
            <div className="absolute top-10 lg:right-12 right-14">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_613_57)">
                        <path d="M39.9998 0L40.5834 36.6912L53.6806 2.412L41.6798 37.09L65.7114 9.358L42.5738 37.8404L74.641 20L43.157 38.8508L79.3922 33.054L43.3598 40L79.3922 46.946L43.157 41.1492L74.641 60L42.5738 42.1596L65.7114 70.6416L41.6798 42.91L53.6806 77.5876L40.5834 43.3088L39.9998 80L39.4162 43.3088L26.319 77.5876L38.3198 42.91L14.2882 70.6416L37.4258 42.1596L5.35902 60L36.8426 41.1492L0.607422 46.946L36.6398 40L0.607822 33.054L36.8426 38.8508L5.35862 20L37.4258 37.84L14.2882 9.3584L38.3198 37.0904L26.319 2.412L39.4162 36.6908L39.9998 0Z" fill="#E898C4" />
                    </g>
                    <defs>
                        <clipPath id="clip0_613_57">
                            <rect width="80" height="80" fill="white" />
                        </clipPath>
                    </defs>
                </svg>


            </div>
        </div>
    )
}


export default Hero;
